import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { collectionPriorityColors } from '@fingo/lib/constants';
import { GET_COLLECTION_PRIORITIES_OPTIONS } from '@fingo/lib/graphql';
import { useEnum } from '@fingo/lib/hooks';
import AvatarWithCaption from '@fingo/lib/components/avatar/AvatarWithCaption';
import { DEFAULT_PRIORITY_SELECT_VALUE } from '../../constants/debtors';

const DebtorsActions = ({ debtorCollectionPriority, setDebtorCollectionPriority }) => {
  const [prioritiesOptions] = useEnum(
    GET_COLLECTION_PRIORITIES_OPTIONS,
    DEFAULT_PRIORITY_SELECT_VALUE,
  );
  return (
    <Select
      id="debtorPaymentBehaviour"
      value={debtorCollectionPriority || ''}
      displayEmpty
      renderValue={(value) => {
        const option = value
          ? prioritiesOptions.find(({ value: prValue }) => prValue === value)
          : DEFAULT_PRIORITY_SELECT_VALUE;
        return <AvatarWithCaption {...option} bgcolor={collectionPriorityColors[value]} />;
      }}
      label="Age"
      onChange={(event) => setDebtorCollectionPriority(event.target.value)}
      variant="outlined"
      sx={{
        width: '20%',
        fontWeight: 600,
      }}
    >
      <MenuItem key="Seleccione" value="Seleccione" disabled>
        Selecciona prioridad
      </MenuItem>
      {prioritiesOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <AvatarWithCaption {...option} />
        </MenuItem>
      ))}
    </Select>
  );
};

DebtorsActions.propTypes = {
  debtorCollectionPriority: PropTypes.number,
  setDebtorCollectionPriority: PropTypes.func.isRequired,
};
DebtorsActions.defaultProps = {
  debtorCollectionPriority: null,
};

export default DebtorsActions;
